import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय २० वा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय २० वा' num='20'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । हे सृष्टी निर्मात्या । तुझ्या कृतीस अंत न तत्वता । तेणे संबोधिले अनंता ॥ १ ॥
तू जी जी कृती करणार । ती कोण वर्णन करणार । ती अपूर्णच रहाणार । तो असमर्थच ठरणार ॥ २ ॥
तुझी कृती अनंत रहाणार । ती सदा बदलत रहाणार । मी काय वर्णन करणार ? । ते अज्ञानच ठरणार ॥ ३ ॥
तू जर सहाय्य करणार । तरच मी लेखणी उचलणार । सरस्वती जरी लिहविणार । परी तुझे सहाय्य असल्यावर ॥ ४ ॥
तू एक योगी निर्मिला । एक समर्थ निर्मिला । तो चमत्कृती करता झाला । स्वलीले भक्तां रक्षिता झाला ॥ ५ ॥
ही सर्व तुझीच लीला । समर्थांच्या ज्या लीला । त्या त्या सर्व तुझ्याच लीला । ऐसेच वाटते सरस्वतीला ॥ ६ ॥
वाटे तुझ्या लीलांना स्मरावे । तुझे गुणगान गावे । त्यातच सभोवतालचे विसरावे । जीवन सार्थक करावे ॥ ७ ॥
स्मरणाचे प्रकार अनेक । ग्रंथलेखन त्यातले एक । जे जे स्मरले कित्येक । त्यांचा संग्रह ग्रंथ एक ॥ ८ ॥
तूच नामाचे घास भरवले । तूच नामाचे महत्व ठसवले । तूच नामात रमवले । तूच नामाने तृप्त केले ॥ ९ ॥
जो रंगणार, एकरूप होणार । तोच लिहिणार, रंगवणार । चित्त एकरूप झाल्यावर । तो त्यात रंगून जाणार ॥ १० ॥
हे सरस्वतीने जाणले । तीने आसनावर बसवले । श्री गणपतीचे सहाय्य लाभले । तेणे ग्रंथलेखन आरंभिले ॥ ११ ॥
एका गुरूंची लीला वर्णिल्याने । गुरू गजाननाची लीला वर्णिल्याने । भक्त डोलतील आनंदाने । गाऊ लागतील आनंदाने ॥ १२ ॥
हे सर्व तुझेच आहे । माझे काय आहे ? । मी ही तुझाच आहे । सर्व तुझीच लीला आहे ॥ १३ ॥
मी खरोखरच अकर्ता । तूच कृतीचा कर्ता । हे जाणतो तत्वता । जेणे तुला झालो विनविता ॥ १४ ॥
गणामहाराज करे प्रार्थना । तू यावेस ह्या स्थाना । दावावे गजानन लीलांना । जे उपयुक्त ग्रंथलेखना ॥ १५ ॥
महाराज जरी समाधीस्त । परी वारंवार दर्शन देतात । भक्तांस सहाय्यभूत होतात । आपली खूण पटवतात ॥ १६ ॥
गणपत कोठाडे भाग्यवान थोर । करे सदा पुण्याचार । ब्राह्मणांस भोजन वारंवार । तेणे मना आनंद फार ॥ १७ ॥
असेच एके दिवशी । विजयादशमीच्या आदल्या दिवशी । आला मंदीरी दर्शनासी । समर्थे प्रेरणा दिली त्यासी ॥ १८ ॥
भरपूर दानधर्म करावा मठाला । गुरूआज्ञा अनुसरता झाला । हे न खपले पत्नीला । टाकून बोलली गणपताला ॥ १९ ॥
पत्नी म्हणे याने खाली होईल कोठार । आधार ठरेल निराधार । तिचे मन घेई माघार । वाटे पुढे करावा दानधर्म अपार ॥ २० ॥
मुकू अलंकार वस्त्राला । परी महाराज आले स्वप्नाला । जागे केले तिला । उपदेश केला तिला ॥ २१ ॥
वागू दे गणपतास । येईल त्याच्या मनास । तू त्याला अडविल्यास । मुकशील अलंकारास ॥ २२ ॥
चूक कळली पत्नीला । महाराजे लावले सन्मार्गाला । जीवनाचा खरा अर्थ कळला । दानधर्म केला दसर्‍याला ॥ २३ ॥
अशाच घरच्या कटकटींचा । अनुभव लक्ष्मण हरी जांजळाचा । काही निमित्त कामाचा । योग आला मुंबईचा ॥ २४ ॥
मुंबईस गेला असता । बोरीबंदरावर  जाता । परमहंसास झाला पहाता । हर्षभरीत झाला चित्ता ॥ २५ ॥
अजानुबाहु उंच मुनीवर । दृष्टी स्थिर नासिकाग्रावर । हाती कमंडलू पासोडी अंगावर । शोभे त्यांच्या गळ्यात हार ॥ २६ ॥
बोलता झाला त्यास । तू अमरावतीस । केले पुण्यतीथीस । केले अमाप दानधर्मास ॥ २७ ॥
चारशे ब्राह्मण आले । यथेच्छ जेवू घातले । पेटकर, बापट मास्तर आले । बापटास स्वप्न पडले ॥ २८ ॥
गजानन सांगे बापटास । नको करू पुत्र शोकास । जावे जांजळाच्या घरास । पुण्यतिथीच्या उत्सवास ॥ २९ ॥
असे म्हणाले नि अदृष्य झाले । जांजळे इकडे तिकडे पाहिले । परी कुणी न त्यास दिसले । आश्चर्य त्यास वाटले ॥ ३० ॥
एकदा माधव मार्तंड जोशास । जावे लागले कामास । कळंब कसूरास । होता गुरूवारचा दिवस ॥ ३१ ॥
वाटे जावे दर्शनास । शेगावी मठास । म्हणाले नोकरास । जोड बैलगाडीस ॥ ३२ ॥
पावसाळ्याचे दिवस । नोकर म्हणे जोशास । ढगाळ वातावरण नभास । पूर येईल मन नदीस ॥ ३३ ॥
नोकराचे म्हणणे न पटले । जोशांनी जाण्याचे ठरवले । बैल गाडीस जुंपले । दोघे शेगावी निघाले ॥ ३४ ॥
अर्ध्या वाटेत पोहोचले । पावसाने थैमान केले । दर्शनास उत्सुकलेले । पुढेच जाऊ लागले ॥ ३५ ॥
पूर आला मन नदीला । जोशी समजले मनाला । ते म्हणाले नोकराला । तुझाच सल्ला खरा ठरला ॥ ३६ ॥
बैलगाडी पुढे जाईना । मागेही जाता येईना । काय करावे सुचेना । महाराजच आठवले त्या क्षणा ॥ ३७ ॥
माधव मार्तंड जोशी म्हणाले । “संतांनी सागरातून वाचवले । येथे तर नदीला पूर आले । तुझ्यावरच आता सोपवले ॥ ३८ ॥
गुरूमहाराज धावून यावे । आम्हांस रक्षावे । आमचे प्राण वाचवावे । आम्हां शेगावी न्यावे” ॥ ३९ ॥
ऐसी मनोमनी प्रार्थना । डोळे घट्ट मिटले तत्क्षणा । पुढचे प्रसंग पहावेना । महाराजांशिवाय काही दिसेना ॥ ४० ॥
ऐसे केले विनवणीस । धावावे लागले महाराजांस । रक्षावे लागले जोशांस । सुखरूप आले शेगावास ॥ ४१ ॥
जोशी वृत्तांत वदले । बाळाभाऊ समजले । समाधीचे दर्शन घेतले । कृपाशिष घेते झाले ॥ ४२ ॥
केले अभिषेक अन्नदान । घेतले पालखीचे दर्शन । प्रसन्न झाले मनोमन । निघाले कृतकृत्य होऊन ॥ ४३ ॥
महाराजांचे भक्त अपार । त्यांच्या कृपादृष्टीचा आधार । हाच ठरे जीवनी आधार । कुणी न होई निराधार ॥ ४४ ॥
असाच एक भक्त यादव गणेश सुभेदार । हिंगणीस घरदार । करे कापसाचा व्यापार । समर्थांवर श्रद्धा अपार ॥ ४५ ॥
आला एकदा वर्ध्याला । विनायक आसिरकरांच्या घराला । गाड्या बरोबर आणल्या । कापसाच्या व्यापाराला ॥ ४६ ॥
होते दोघे ओसरीवर । एक भिकारी आला त्यावर । भिकारी येत असती वरचेवर । भीक मागून भरती उदर ॥ ४७ ॥
परी हा भिकारी वेगळा । कंप सुटलेला तनूला । बनातीची टोपी घातलेला । हातात काठी असलेला ॥ ४८ ॥
बोलता झाला यादवाला । रुपये मागता झाला । सत्वर द्यावे तू मजला । आलो येथे भीक मागायला ॥ ४९ ॥
परी तो संतुष्ट होईना । तेथून काही जाईना । कसा घालवावा कळेना । यादवास काही सुचेना ॥ ५० ॥
गेला विनायक इतुक्यात । कामानिमित्ते घरात । भिकारी म्हणे तेवढ्यात । दहा हजार रुपये तोटा व्यापारात ॥ ५१ ॥
हात फिरवतो तुझ्यावरी । दुखणे जाईल सत्वरी । आवाज होता समर्थांपरी । जणू उभी समर्थ स्वारी ॥ ५२ ॥
परी यादव होता हुशार । बोलता झाला त्यावर । आज फायदा झाल्यावर । तुला महाराज म्हणणार ॥ ५३ ॥
भिकारी अदृष्य झाला । यादव त्याच विचारात गुंतला । इकडे व्यापार सुरू झाला । पहाता पहाता नफा झाला ॥ ५४ ॥
खूण पटली यादवास । व्यापारात फायदा झाला त्यास । आराम पडला दुखण्यास । दर्शन दिले यादवास ॥ ५५ ॥
अशीच कथा भाऊ कवराची । तेल्हारी बदली झाली भाऊची । ओढ चित्ती शेगावची । ओढ चित्ती दर्शनाची ॥ ५६ ॥
वाटे आधी करावे दर्शन । तेल्हारी जावे तेथून । बैलगाडीस जुंपून । निघाला तो मुक्कामाहून ॥ ५७ ॥
संध्याकाळी दर्शना गेला । दर्शन करून निघता झाला । बाळाभाऊ त्यास म्हणाला । जाऊ नये व्यतीपाताला ॥ ५८ ॥
येथे उद्या यावे प्रसादास । मग जावे तेल्हार्‍यास । सुटका नव्हती भाऊस । गेल्यावाचून तेल्हार्‍यास ॥ ५९ ॥
निरोप घेऊन स्वारी निघाली । तेल्हार्‍याची वाट धरली । गाडी पुढे चालतच राहिली । परी शेगावची वाट न सोडली ॥ ६० ॥
बैल थांबले तलावावर । गाडीवान आला भानावर । म्हणे रस्ता चुकलो सरकार । गाडी पुढे न जाणार ॥ ६१ ॥
राग आला भाऊस । बोलू लागला गाडीवाल्यास । स्मरले त्याने महाराजांस । ऐकले घुंगराच्या आवाजास ॥ ६२ ॥
भाऊ म्हणे गाडीवानास । “ऐक आवाजाच्या रोखास । तसेच जाऊदे गाडीस । तेल्हार्‍याच्या वाटेस” ॥ ६३ ॥
पुढे पहातो तो वाट शेगावची । चमत्कृती कळली महाराजांची । डोळ्यासमोर स्वारी बाळाभाऊची । खूण पटली अधिकाराची ॥ ६४ ॥
भाऊ मठात परतला । त्या दिवशी प्रसाद घेता झाला । नंतर गेला तेल्हार्‍याला । असा व्यतीपात योग टळला ॥ ६५ ॥
ऐसे रक्षिले भाऊ कवरास । असेच रक्षिले दिनकरास । थोडे वेगळे प्रकारास । परी महत्व रक्षणास ॥ ६६ ॥
शेगावी रतनसा एक भक्त । दिनकर त्याचा सुपुत्र । झाला एकदा व्याधियुक्त । सोबणी रोग त्याप्रत ॥ ६७ ॥
अन्नपाणी जाईना । औषध लागू पडेना । जरी इलाज केले नाना । परी उपयोग होईना ॥ ६८ ॥
रतनसा कंटाळला दुखण्याला । आराम न वाटे दिनकराला । अंगात त्राण न उरला । नवस करता झाला ॥ ६९ ॥
बरे वाटू दे दिनकराला । शेरणी देईन तुला । पाव आता नवसाला । रक्षावे आता भक्ताला ॥ ७० ॥
विपरित घडता डोके फोडीन । वर्‍हाडात नाचक्की करीन । नको येऊ दे ऐसा क्षण । करावे भक्त रक्षण ॥ ७१ ॥
असेच एकदा चंद्रभागेला । रामचंद्राच्या कन्येला । टेकली असता बाळंतपणाला । दुखणे झाले तिला ॥ ७२ ॥
लाडेगावाहुन आणली तिला । माहेरी बाळंतपणाला । दाखवले डॉक्टर वैद्याला । निदान न झाले कुणाला ॥ ७३ ॥
रामचंद्र तिचा पिता । महाराजांचा भक्त होता । जरी औषध झाला करता । परी गुण येत नव्हता ॥ ७४ ॥
कंटाळला औषधास । म्हणाला महाराजांस । तीर्थ अंगारा देतो चंद्रभागेस । आराम पडूद्या दुखण्यास ॥ ७५ ॥
औषधोपचार बंद केले । तीर्थ अंगार्‍यावर ठेवले । बरेच दिवस असे गेले । हळुहळु दुखणे कमी झाले ॥ ७६ ॥
श्रद्धा आली फळास । आराम पडला कन्येस । चंद्रभागा गेली दर्शनास । म्हणे “स्वामी तुम्हीच आता सहाय्यास” ॥ ७७ ॥
असेच एकदा जानकाबाईस । रामचंद्राच्या पत्नीस । वात विकाराचे दुखणे तीस । काही सुचेना तीस ॥ ७८ ॥
नाना ते बोलावे । वाटेलते चाळे करावे । डोके फिरल्यास्तव वागावे । भ्रमिष्टासारखे वागावे ॥ ७९ ॥
कधी जेवू नये । उपाशीच रहावे । वाटेल ते खावे । कधी उपाशीच झोपावे ॥ ८० ॥
कुणी म्हणे भूतबाधा । कुणी म्हणे करणी बाधा । कुणी म्हणे अंगरोग बाधा । कुणी मानसिक रोग बाधा ॥ ८१ ॥
औषधोपचार झाले । डॉक्टर वैद्य झाले । मांत्रिक तांत्रिक झाले । गंडे दोरे ताईत झाले ॥ ८२ ॥
औषधे उपयोगी पडेना । दुखण्यास आराम पडेना । स्वस्थ झोप येईना । रामचंद्रास काही सुचेना ॥ ८३ ॥
जीवनास कंटाळला । जात होता दर्शनाला । एक दिवस आळवून म्हणाला । आता शेवटच्या नवसाला ॥ ८४ ॥
प्रदक्षिणा घालीन मंदिरास । आराम पडुद्या दुखण्यास । कंटाळलो आता औषधोपचारास । मांत्रिकाच्या इलाजास ॥ ८५ ॥
त्याने सांगितले पत्नीस । पत्नीने प्रदक्षिणा घातल्या मंदिरास । आराम पडला दुखण्यास । श्रद्धा आली फळास ॥ ८६ ॥
महाराज समाधिस्त झाल्यावर । बाळाभाऊ आले गादीवर । वागती गुरूआज्ञेनुसार । ठरले भक्तांस आधार ॥ ८७ ॥
सेवा करीत होते मठाची । सोबत होती समर्थकृपेची । बरोबर तीर्थ अंगार्‍याची । फळे मिळाली श्रद्धेची ॥ ८८ ॥
आधार ठरले भक्तांस । मार्गदर्शक झाले त्यांस । वैशाख शुद्ध षष्ठीस । ते गेले वैकुंठास ॥ ८९ ॥
नांदुर्‍याच्या नारायणास । समर्थे स्वप्नी सांगितले त्यास । जावे तू शेगावास । नारायणे प्रमाण मानले त्यास ॥ ९० ॥
नारायण गेले शेगावास । चालवले अधिकारास । काही काळाने चैत्र शुद्ध षष्ठीस । ते गेले वैकुंठास ॥ ९१ ॥
एकदा मंदीराच्या दुरुस्ती कामात । मजूर होते त्याच्या कामात । अगदी मग्न होते त्यात । कळसाच्या शिखरात ॥ ९२ ॥
तीस फूट उंचावर । दगड झेलित होते वरचेवर । गर्क होते दगड देण्यात । एकमेकांच्या हातात ॥ ९३ ॥
त्यात एकाचा तोल गेला । क्षणभरात खाली पडला । न आपटता झेलला गेला । प्राण त्याचा वाचला ॥ ९४ ॥
सहज तो खाली उतरला । म्हणे कुणी उचलले मला ? । क्षणभरात गोंधळला । लोक बघते झाले त्याला ॥ ९५ ॥
ज्याने त्यास रक्षिले । “महाराजच” सर्व म्हणाले । गवंड्याचे प्राण रक्षिले । भक्तास पुनर्जीवन मिळाले ॥ ९६ ॥
अशीच महाराजांची भक्तीण । जयपूरात सासुरवाशीण । करावे तिने गुरूंचे स्मरण । वेळोवेळी व्हावे रक्षण ॥ ९७ ॥
भूतपिशाच्च पीडा झाली तीस । विचित्र वागावे हरघडीस । वाटेल ते मागावे खावयास । वाटेल त्या समयास ॥ ९८ ॥
झोप काही येईना । चैन काही पडेना । अपशब्द बोले नाना । औषधोपचार लागू पडेना ॥ ९९ ॥
महाराजे तिला दिली प्रेरणा । म्हणती शेगावी जावे दर्शना । आराम पडेल दुखण्या । विसरशील यातना ॥ १०० ॥
प्रमाण मानली गुरूआज्ञा । घेतले आपल्या दोन मुलांना । आली शेगावी दर्शना । दुखण्यातून ती सुटण्या ॥ १०१ ॥
रामनवमीचे दिवस होते । उत्सवाचे दिवस होते । मंदिराचे बांधकाम चालू होते । आधारास खांब उभे होते ॥ १०२ ॥
उभी होती दगडी खांबाजवळी । खांब सरकला त्यावेळी । तत्क्षणी ती खाली पडली । वाटले कपाळमोक्ष झाली ॥ १०३ ॥
लोकांना वाटले । बाईचे वाईट झाले । खांबास उचलले । बाजूस केले ॥ १०४ ॥
सर्वजण तिला पहाते झाले । डॉक्टरांना बोलावले । डॉक्टरांनी तपासले । दुखापतीचे निशाण न दिसले ॥ १०५ ॥
सर्वांना आश्वर्य वाटले । महाराजे तिला रक्षिले । भूत पिशाच्च दूर पळाले । त्यासाठीच खांबाचे पडणे झाले ॥ १०६ ॥
रामचंद्र पाटिल निःसीम भक्त । श्रद्धेने होता वागत । दररोज जाई मठात । समाधीचे दर्शन घेत ॥ १०७ ॥
महाराजांना आळवत । महाराजांशी बोलत । दुखले खुपले सांगत । त्यांची आज्ञा पाळत ॥ १०८ ॥
एक दिवस उजाडला । दारात साधू आला । रामचंद्रास पाहू लागला । त्यास न्याहाळू लागला ॥ १०९ ॥
आश्चर्य वाटले रामचंद्रास । निरखुन पाहिले साधूस । महाराजांचाच भास । महाराजच वाटे त्यास ॥ ११० ॥
त्यांना पाटावर बसवले । आदरातिथ्य केले । पाच रुपये दक्षिणा दिले । काय आज्ञा ? ते विचारले ॥ १११ ॥
महाराज म्हणती त्यास । काय देतो दक्षिणेस ? । पहावे मठाच्या कारभारास । सेवा हीच दक्षिणा आम्हास ॥ ११२ ॥
देतो ताईत तुझ्या मुलास । चेटुक करणी न बाधेल त्यास । आराम पडेल दुखण्यास । शांती लाभेल मनास ॥ ११३ ॥
महाराजे केले उपदेश । पाळावे गुरू आदेश । आचरू नये दंभाचारास । तैसेच मत्सर द्वेषास ॥ ११४ ॥
साधू भोंदू ओळखावा । उगाच वेळ न दवडावा । साधूस विन्मुख न पाठवावा । दानधर्म करावा ॥ ११५ ॥
लोभास ओळखावा । लोभ न आचरावा । मनी हेतू शुद्ध ठेवावा । तैसाच वर्ताव करावा ॥ ११६ ॥
गुरूनिष्ठेत रहावे । गुरूआज्ञेत रहावे । राजाविरुद्ध जाऊ नये । दुसर्‍या कुणाचे हिरावू नये ॥ ११७ ॥
शुद्ध असावे अंतःकरण । तैसेच कृतीत आचरण । मुखी असू दे नामस्मरण । तेच करेल रक्षण ॥ ११८ ॥
अतीक्रोध करे घात । दया असावी अंतरंगात । शब्द जपावे बोलण्यात । दक्ष असावे कर्तव्यात ॥ ११९ ॥
असे सांगता झाला । गोसावी घराबाहेर पडला । रामचंद्र त्यास पाहू लागला । परी तो कोठे न दिसला ॥ १२० ॥
ऐसे झाले रामचंद्रास दर्शन । असेच होवो तुम्हा दर्शन । दर्शनाने आनंदेल मन । आनंदातच शांती समाधान ॥ १२१ ॥
भव्य दिव्य मंदीरास । वर्गणी जमवली त्यास । अनेकांचे सहाय्य लाभले त्यास । मंदिर गेले पूर्णत्वास ॥ १२२ ॥
किसनलाले केला शतचंडीयज्ञ । बोलावले त्यास ब्राह्मण । भरपूर केले अन्नदान । पूर्ण झाले अनुष्ठान ॥ १२३ ॥
हे अनुष्ठान अत्यंत अवघड । चूक होता जाते तड । पूर्ण करण्यास गुरूकृपा जोड । तेणेच पूर्णत्वाची जोड ॥ १२४ ॥
आग्रवाल बंकटलालास । किसनलालच्या वडिलांस । आले दुखणे ह्याच समयास । दुखणे गेले विकोपास ॥ १२५ ॥
जणू काळाने बोलावले । सर्वजण घाबरले । महाराजांस स्मरले । यज्ञ पूर्ण करण्यास सांगितले ॥ १२६ ॥
यज्ञ पूर्णत्वास गेला । बंकटलाल बरा झाला । गुरूकृपेचा प्रभाव झाला । सर्वांचा भार हलका झाला ॥ १२७ ॥
जैसे महाराज दर्शन देती दुसर्‍यांस । तैसेच मिळो आपणांस । हीच ईच्छा गणामहाराजास । येथेच विसावा अध्याय जातो पूर्णत्वास ॥ १२८ ॥
 
॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य विंशोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
